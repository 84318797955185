@use "../config/" as *;
.waf-statslisting {
    @extend %p-4;
    .tab-container-head {
        .title {
            text-transform: none;
        }
    }
    .waf-head {
        .title {
            @extend %mb-4;
            @extend %font-16-pb;
            @extend %secondary-800;
        }
    }
    .bio {
        @extend %p-4;
        @extend %half-radius;
        @extend %primary-50-bg;
        @extend %mb-4;
        p {
            line-height: 2rem;
            @extend %font-14-pr;
        }
        strong {
            @extend %font-14-pb;
        }
    }
    .accordion {
        &-header {
            padding-right: var(--space-6);
            min-height: 5.6rem;
            @extend %flex-sb-c;
            @extend %py-2;
            @extend %px-5;
            @extend %w-100;
            @extend %half-radius;
            @extend %secondary-50-bg;
            &::after {
                @include icon(p-arrow-right);
                @extend %neutral-800;
            }
            &[aria-expanded="true"] {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                &::after {
                    transition: .3s all ease;
                    transform: rotate(90deg);
                }
                ~ .accordion-body {
                    grid-template-rows: 1fr;
                }
            }
            .btn-text {
                line-height: 2rem;
                @extend %text-left;
                @extend %font-14-pb;
                @extend %capitalize;
                @extend %neutral-900;
            }
        }
        &-body {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 0.5s ease-out;
            .stats-link {
                &-text {
                    @extend %font-14-pm;
                    @extend %neutral-600;
                }
                &-list {
                    list-style: none;
                    @extend %p-0;
                }
                &-item {
                    @extend %p-4-5;
                }
            }
            .stats-link-list,
            .custom-form {
                overflow: hidden;
                border-radius: 0 0 var(--half-radius) var(--half-radius);
                @extend %pure-white-900-bg;
            }
        }
        &-item {
            @extend %mb-4;
        }
    }
    .form {
        &-wrapper {
            @extend %w-100;
            @extend %gap-4;
            @extend %flex-column-c-c;
            @extend %p-5;
            .btn {
                @extend %flex-c-c;
                @extend %p-3;
                @extend %primary-300-bg;
                @extend %half-radius;
                @extend %w-100;
                &-section {
                    @extend %w-100;
                }
                &-text {
                    @extend %font-12-pb;
                    @extend %neutral-800;
                    @extend %uppercase;
                }
            }
            .versus {
                @extend %uppercase;
                @extend %font-12-pb;
                @extend %neutral-900;
            }
        }
        &-group {
            @extend %w-100;
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
        &-label {
            @extend %d-none;
        }
        &-dropdown {
            top: 4.7rem;
        }
        &-input-drop {
            @extend %py-5;
            &[aria-expanded="true"] {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
            }
            .btn-text {
                @extend %font-12-pm;
                @extend %neutral-900;
            }
        }
    }
    .no-data-wrapper {
        @extend %text-center;
        @extend %p-4-5;
        .no-data {
            @extend %neutral-900;
            @extend %uppercase;
            @extend %font-14-pb;
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-statslisting {
        .waf-head .title {
            font-size: 1.6rem;
            margin-bottom: var(--space-6);
        }
        .form-wrapper {
            .btn {
                padding: var(--space-4);
            }
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-statslisting {
        .tab-container-body {
            gap: var(--space-4);
            flex-wrap: wrap;
            @include flex-config(flex, null, center);
        }
        .accordion-group {
            width: calc((100% - var(--space-4)) / 2);
        }
        .form-group {
            width: 100%;
        }
    }
}